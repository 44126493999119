import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`100% of our service users who took part in the Qualsafe Level 2 Award in Food Safety in Catering passed their test.`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/150784d983e33b960677eac220ac640d/c1b63/food-safety.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAC4jAAAuIwF4pT92AAACZUlEQVQoz62TS0hUcRTG/7UIgzaBEVlQ1KY2rayFRg+IilpEmygxwbZF9EYjqUULq0WmlpWljvkcHR8zo6GOYTiGz5s2Lx0bnfLO3NErlkpQ4733FzMJFbSKDvw43/edszxH8J9LuL9EieFZJq7no3j/wvj8d5SvURa+RVlYXEQOhwlOyfj9Y4Rkmbm5OUSSNcIma4T1zRE2WiNstU+T2KQgasMIs/IHWxomOOsYpbLvPRZ7MxmZady+eIFbN7OpMTcwMORFpFq8bCsfZme1i2Szh5R6L7vMHta9kBAFfYjCZYokNpdKZNpcPO0YoKG1G+srO/b8XKTefuxtb7A0NiMOmSXSbW4OVA9x2CxxyuriuGWYfVWDiNwOxF0H4v5rRJ6TDYVdnDB1cq2olKycbD5NRVBCMWbIunGHMxkZiCNmidSKAU42jZBuc3GsTiLN6mK3qS+eby9+i7jXyco8J0n5nRwtspPzxERlTQWyPM2M+hk1MkuZqZpLl68jEsrHWGUaZW3VOIlVfhJMoySZA6x5Gct9rCjxIp65EcU+Vhc4SXloI+d5E9W1NoYcHUx6fARHxynLe8C581cQyS0hDjoU9raH2WELs6dNYX97OO6TW2M95pX4zum2ca42Oilp7cLh6KKnw4Grp4eR/kHqHj+i3lSOaPFGcIdmeTelUupW6Z5QcckqfR9V6n0z9E6qOCdUeoMqUnAa36SMPxAkEJhADkcIKWEmA34CH8aYUUII3TCI6gZLugGGgab/9DFis1iuLc+jms6SpqFpGoZhYOg6uq7F0bQlNF1HGMavK/9d/+un/ACM683/CL/HDwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "food safety in catering",
            "title": "food safety in catering",
            "src": "/static/150784d983e33b960677eac220ac640d/5a190/food-safety.png",
            "srcSet": ["/static/150784d983e33b960677eac220ac640d/772e8/food-safety.png 200w", "/static/150784d983e33b960677eac220ac640d/e17e5/food-safety.png 400w", "/static/150784d983e33b960677eac220ac640d/5a190/food-safety.png 800w", "/static/150784d983e33b960677eac220ac640d/c1b63/food-safety.png 1200w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Aiding & Educating women to a brighter future`}</h3>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      